<template>
  <div>
    <div class="card"
    v-loading="loading"
    element-loading-text="PSA-Suche läuft..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)">
      <div class="card-header">
        <div class="row">
          <div class="col-sm-9">
            <h5 class="card-title">PSA-Suche</h5>
          </div>
        </div>
      </div>
      <div class="card-body row justify-content-center">
        <div class="col-sm-6">
          <h6 style="text-align: center;"><b>Serien-Nr. eingeben oder RFID-Chip scannen.</b></h6>
          <el-input
            placeholder="Serien-Nr. eingeben oder scannen"
            v-model="searchTerm"
            prefix-icon="el-icon-search"
            clearable
            @change="findPsa"
            class="mb-3"
            id="searchbox">
          </el-input>
          <el-alert
            title="Es konnte kein eindeutiges Ergebnis gefunden werden."
            type="warning"
            effect="dark"
            show-icon
            center
            v-if="multipleResults == true">
          </el-alert>
          <el-alert
            title="Es konnte keine PSA mit dieser Seriennummer gefunden werden."
            type="warning"
            effect="dark"
            show-icon
            center
            v-if="noResults == true">
          </el-alert>
          <p-button type="success" @click="findPsa()" block size="lg" class="mt-3" :disabled="searchTerm == ''">Suchen</p-button>

          <img src="/static/img/rfid-icon.png" alt="RFID" class="img-fluid mx-auto d-block mt-4 mb-2" width="200px" >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { db } from "../../../../firebaseDb";
import { Input, Loading} from "element-ui";
import moment from "moment-timezone";
import router from "../../../../main";
import store from "../../../../store";
Vue.use(Loading);

export default {
  components: {
    [Input.name]: Input,
  },

  data() {
    return {
      loading: false,
      searchTerm: "",
      multipleResults: false,
      noResults: false,
    };
  },
  created() {
    moment.locale("de");
  },
  mounted() {
    document.getElementById("searchbox").select();
  },
  methods: {
    showDetails(docId) {
      if (docId) {
        router.push({ name: "PsaDetails", params: { psaId: docId } });
      }
    },
    findPsa() {
      this.loading = true;
      this.multipleResults = false;
      this.noResults = false;

      const psaRef = db.collection("psa");
      psaRef.where('SerialNumber', '==', this.searchTerm).onSnapshot((snapshotChange) => {
        if (snapshotChange.size > 1) {
          this.multipleResults = true;
          this.loading = false;
        }
        if (snapshotChange.size == 0) {
          this.noResults = true;
          this.loading = false;
        }
        if (snapshotChange.size == 1) {
          this.noResults = false;
          this.multipleResults = false;
          this.loading = false;
          this.searchTerm = "";
          this.showDetails(snapshotChange.docs[0].id);
        }
      });
      this.loading = false;
    }
  },
};
</script>
<style>

</style>
